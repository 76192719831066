<template>
  <section
    style="padding-left: 25px;
  margin-left: 20px; padding-right: 10px;
  margin-right: 10px;"
  >
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-row>
          <v-col cols="12" sm="12" md="3">
            <v-btn @click="addCard = !addCard" class="info  ml-6 mr-2 mt-4 ">
              <v-icon left> {{ icons.mdiDatabasePlusOutline }} </v-icon>
              Ajouter {{ formatCategory(this.$route.params.category) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="addCard">
      <v-col cols="12" sm="12" md="12">
        <v-card class="mb-6 ml-6 mr-2 mt-1">
          <v-form ref="form" lazy-validation>
            <v-card-title>
              Ajouter
              {{ formatCategory(this.$route.params.category) }}</v-card-title
            >
            <v-card-text class="mt-4">
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="title"
                    label="Titre"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="overview"
                    label="Overview"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  v-if="
                    $route.params.category !== 'PERSONNAGE' &&
                      $route.params.category !== 'PATRIMOINE' &&
                      $route.params.category !== 'ARTICLE'
                  "
                >
                  <v-text-field
                    label="Sous-Titre"
                    v-model="subtitle"
                    outlined
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-if="
                    $route.params.category !== 'PERSONNAGE' &&
                      $route.params.category !== 'PATRIMOINE'
                  "
                >
                  <VueEditor
                    v-model="description"
                    auto-grow
                    outlined
                    label="Description"
                    placeholder="Description"
                  >
                  </VueEditor>
                </v-col>

                <v-col cols="12" sm="12" md="4">
                  <v-row align="center">
                    <v-col cols="auto" style="margin-top: 16px;">
                      <span>Premium:</span>
                    </v-col>
                    <v-col cols="auto">
                      <v-switch v-model="Premium" hide-details inset></v-switch>
                    </v-col>
                    <v-col cols="auto" style="margin-top: 16px;">
                      <v-icon color="#00a325" v-if="!Premium">
                        {{ icons.mdiLockOpen }}
                      </v-icon>
                      <v-icon color="warning" v-if="Premium">
                        {{ icons.mdiLock }}
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="4" v-if="title">
                  <vue-upload-multiple-image
                    :idUpload="imagesId"
                    v-model="images"
                    :data-images="images"
                    name="images"
                    drag-text="Veuillez ajoutez une image"
                    browse-text="Browse image"
                    primary-text="Primary image"
                    mark-is-primary-text="Set default image"
                    :min-image="1"
                    :multiple="false"
                    :showEdit="false"
                    popup-text="Description default image"
                    drop-text="Drag and drop"
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                    @images-updated="handleImagesUpdated"
                  >
                  </vue-upload-multiple-image>
                  <v-progress-linear
                    v-if="showlowding && uploadPercentage < 100"
                    style="max-width: 190px; "
                    color="primary"
                    indeterminate
                    rounded
                    height="15"
                    ><strong
                      >{{ Math.ceil(uploadPercentage) }}%</strong
                    ></v-progress-linear
                  >
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  v-if="
                    (title && $route.params.category === 'PERSONNAGE') ||
                      (title && $route.params.category === 'PATRIMOINE') ||
                      (title && $route.params.category === 'ARTICLE')
                  "
                >
                  <vue-upload-multiple-image
                    :idUpload="imagesIdBanner"
                    v-model="imagesBanner"
                    :data-images="imagesBanner"
                    name="images"
                    drag-text="Veuillez ajoutez une image Banner!"
                    browse-text="Browse image"
                    primary-text="Primary image"
                    mark-is-primary-text="Set default image"
                    :min-image="1"
                    :multiple="false"
                    :showEdit="false"
                    popup-text="Description default image"
                    drop-text="Drag and drop"
                    @upload-success="uploadImageSuccessBanner"
                    @before-remove="beforeRemoveBanner"
                    @images-updated="handleImagesUpdatedBanner"
                  >
                  </vue-upload-multiple-image>
                  <v-progress-linear
                    v-if="showlowdingBanner && uploadPercentageBanner < 100"
                    style="max-width: 190px; "
                    color="primary"
                    indeterminate
                    rounded
                    height="15"
                    ><strong
                      >{{ Math.ceil(uploadPercentageBanner) }}%</strong
                    ></v-progress-linear
                  >
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-if="
                    $route.params.category === 'PERSONNAGE' ||
                      $route.params.category === 'PATRIMOINE'
                  "
                >
                  <div class="d-flex flex-row-reverse">
                    <v-btn
                      :disabled="title.length <= 0"
                      class="info"
                      @click="ajoutactuality()"
                    >
                      Suivant
                    </v-btn>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-else-if="$route.params.category === 'ARTICLE'"
                >
                  <div class="d-flex flex-row-reverse">
                    <v-btn
                      :disabled="title.length <= 0 || description.length <= 0"
                      class="info"
                      @click="ajoutactuality()"
                    >
                      Suivant
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-else>
                  <div class="d-flex flex-row-reverse">
                    <v-btn
                      :disabled="
                        title.length <= 0 ||
                          subtitle.length <= 0 ||
                          description.length <= 0
                      "
                      class="info"
                      @click="ajoutactuality()"
                    >
                      Suivant
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mb-6 ml-6 mt-6 mr-2">
      <v-card-title
        >Liste des
        {{ formatCategory(this.$route.params.category) }}</v-card-title
      >
      <v-text-field
        style="padding:15px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
        :search="search"
      ></v-text-field>
      <v-data-table
        :search="search"
        :footer-props="{
          'items-per-page-options': [5, 10, 15, 30],
        }"
        class="elevation-1"
        item-key="id"
        :headers="filteredHeaders"
        :items="actualityList"
      >
        <template v-slot:item.isLocked="{ item }">
          <v-icon color="primary" v-if="item.isLocked">
            {{ icons.mdiLock }}
          </v-icon>
          <v-icon color="#00a325" v-if="!item.isLocked">
            {{ icons.mdiLockOpen }}
          </v-icon>
        </template>
        <template v-slot:item.imgUrl="{ item }">
          <v-img
            style="margin:10px"
            :src="item.imgUrl"
            max-width="120"
            max-height="80"
          />
          <v-divider></v-divider>
        </template>
        <template v-slot:item.modifier="{ item }">
          <v-icon @click="redirectToEditActuality(item)">
            {{ icons.mdiPencil }}
          </v-icon>
        </template>
        <template v-slot:item.supprimer="{ item }">
          <v-icon @click="suppressionAction(item.id)">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-row justify="center">
      <v-dialog max-width="530" v-model="dialogRemoveActuality">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir supprimer l'actualité ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="deleteActuality()" color="info darken-1 " text>
              Confirmer
            </v-btn>
            <v-btn
              @click="dialogRemoveActuality = false"
              color="error darken-1 error"
              text
            >
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </section>
</template>
<script>
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { component as VueCodeHighlight } from "vue-code-highlight";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiPencil,
  mdiDatabasePlusOutline,
  mdiLockOpen,
  mdiLock,
} from "@mdi/js";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import axios from "axios";
import Constant from "@/utils/constants";
export default {
  components: { VueUploadMultipleImage, VueEditor, VueCodeHighlight },
  data() {
    return {
      imagesId: "1",
      imagesIdBanner: "0",
      search: "",
      uploadPercentage: null,
      uploadPercentageBanner: null,
      fakePercentage: null,
      showlowding: false,
      showlowdingBanner: false,
      dialogRemoveActuality: false,
      actualityIdSupprimmer: "",
      images: [],
      imagesBanner: [],
      imagesUpload: [],
      imagesUploadBanner: [],
      imageUrlBanner: "",
      imageIdBanner: null,
      imageUrl: "",
      imageId: null,
      title: "",
      overview: "",
      Premium: false,
      addCard: false,
      subtitle: "",
      description: "",
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiPencil,
        mdiDatabasePlusOutline,
        mdiLockOpen,
        mdiLock,
      },
      headers: [
        { text: "Image", value: "imgUrl" },
        { text: "Titre", value: "title" },
        { text: "Overview", value: "overview" },
        { text: "Sous-Titre", value: "subtitle" },
        { text: "Premium", value: "isLocked" },
        { text: "Modifier", value: "modifier", align: "center" },
        { text: "Supprimer", value: "supprimer", align: "center" },
      ],
      actualityList: [],
      imageInfo: {
        fileName: "",
        fileUrl: "",
      },
      imageInfoBanner: {
        fileNameBanner: "",
        fileUrlBanner: "",
      },
      actualityInfo: {
        title: "",
        overview: "",
        subtitle: "",
        description: "",
        isLocked: false,
        actualityCategoryId: "",
        imgUrlId: null,
        imgBannerUrlId: null,
      },
    };
  },
  async beforeRouteEnter(to, from, next) {
    const CategoryId = to.params.id;
    try {
      const response = await axios.get(
        `${Constant.QORANI}actuality/getByCategoryId/${CategoryId}`
      );
      next((vm) => {
        vm.actualityList = response.data;
      });
    } catch (error) {
      console.error("Error fetching product details:", error);
      next(null);
    }
  },
  methods: {
    redirectToEditActuality(item) {
      this.$router.push({
        name: "EditActuality",
        params: { id: item.id, item: item },
      });
    },
    suppressionAction(item) {
      this.actualityIdSupprimmer = item;
      this.dialogRemoveActuality = true;
    },
    async deleteActuality() {
      const response = await fetch(
        Constant.QORANI + `actuality/delete/${this.actualityIdSupprimmer}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      try {
        window.location.reload();
      } catch (error) {
        alert(
          "Une erreur est survenue lors de la suppression du juz, veuillez réessayez plus tard, Merci."
        );
        this.dialogRemoveActuality = false;
      }
    },
    convertImagePathToFile(element) {
      const byteString = atob(element.path.split(",")[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ia], { type: "image/jpeg" });

      return new File([blob], element.name);
    },
    beforeRemove(index, done, fileList) {
      this.showlowding = false;
      this.fakePercentage = 0;
      this.uploadPercentage = 0;
      const r = confirm("remove image");
      if (r === true) {
        const indexInArrayImages = this.imagesUpload.indexOf(
          fileList[index].path
        );
        this.imageUrl = "";
        this.imageId = "";
        if (index > -1) {
          this.imagesUpload.splice(indexInArrayImages, 1);
        }

        done();
      }
    },
    beforeRemoveBanner(index, done, fileList) {
      this.showlowdingBanner = false;
      this.fakePercentage = 0;
      this.uploadPercentageBanner = 0;
      const r = confirm("remove image");
      if (r === true) {
        const indexInArrayImages = this.imagesUploadBanner.indexOf(
          fileList[index].path
        );
        this.imageUrlBanner = "";
        this.imageIdBanner = "";
        if (index > -1) {
          this.imagesUploadBanner.splice(indexInArrayImages, 1);
        }

        done();
      }
    },
    uploadImageSuccess(formData, index, fileList) {
      const imageName = fileList[index].name;
      const ext = imageName.slice(imageName.lastIndexOf(".") + 1);
      const contentType = `image/${ext}`;
      const currentDateTime = new Date();
      const formattedDateTime = `${currentDateTime.getFullYear()}${(
        currentDateTime.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}${currentDateTime
        .getDate()
        .toString()
        .padStart(2, "0")}${currentDateTime
        .getHours()
        .toString()
        .padStart(2, "0")}${currentDateTime
        .getMinutes()
        .toString()
        .padStart(2, "0")}${currentDateTime
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      const newImageName = `${formattedDateTime}`;
      const file = this.convertImagePathToFile(fileList[index]);

      const storageRef = firebase
        .app()
        .storage()
        .ref(`actuality/${newImageName}`);
      try {
        this.showlowding = true;
        const metadata = {
          contentType: contentType,
        };
        const uploadTask = storageRef.put(file, metadata);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.uploadPercentage = progress;
          },
          (error) => {
            alert(`Error uploading image ${error.message}`);
          },
          async () => {
            const url = await storageRef.getDownloadURL();
            this.imageUrl = url;
            this.imageInfo.fileName = imageName;
            this.imageInfo.fileUrl = url;

            try {
              const response = await fetch(Constant.QORANI + "files/upload", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(this.imageInfo),
              });

              if (response.ok) {
                const responseData = await response.json();
                this.imageId = responseData.id;
                this.showLoading = false;
              } else {
                this.showLoading = false;
                console.error(
                  "Error uploading image. Server response status:",
                  response.status
                );
              }
            } catch (error) {
              console.error("Error uploading image:", error);
            }

            this.showLoading = false;
          }
        );
        this.showLoading = false;
      } catch (error) {
        this.showlowding = false;
        alert(`Error uploading image ${error.message}`);
      }
    },
    uploadImageSuccessBanner(formData, index, fileList) {
      const imageName = fileList[index].name;
      const ext = imageName.slice(imageName.lastIndexOf(".") + 1);
      const contentType = `image/${ext}`;
      const currentDateTime = new Date();
      const formattedDateTime = `${currentDateTime.getFullYear()}${(
        currentDateTime.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}${currentDateTime
        .getDate()
        .toString()
        .padStart(2, "0")}${currentDateTime
        .getHours()
        .toString()
        .padStart(2, "0")}${currentDateTime
        .getMinutes()
        .toString()
        .padStart(2, "0")}${currentDateTime
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      const newImageName = `${formattedDateTime}`;
      const file = this.convertImagePathToFile(fileList[index]);

      const storageRef = firebase
        .app()
        .storage()
        .ref(`actuality/${newImageName}`);
      try {
        this.showlowdingBanner = true;
        const metadata = {
          contentType: contentType,
        };
        const uploadTask = storageRef.put(file, metadata);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.uploadPercentageBanner = progress;
          },
          (error) => {
            alert(`Error uploading image ${error.message}`);
          },
          async () => {
            const url = await storageRef.getDownloadURL();
            this.imageUrlBanner = url;
            this.imageInfoBanner.fileName = imageName;
            this.imageInfoBanner.fileUrl = url;

            try {
              const response = await fetch(Constant.QORANI + "files/upload", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(this.imageInfoBanner),
              });

              if (response.ok) {
                const responseData = await response.json();
                this.imageIdBanner = responseData.id;
                this.showLoadingBanner = false;
              } else {
                this.showLoadingBanner = false;
                console.error(
                  "Error uploading image. Server response status:",
                  response.status
                );
              }
            } catch (error) {
              console.error("Error uploading image:", error);
            }

            this.showLoadingBanner = false;
          }
        );
        this.showLoadingBanner = false;
      } catch (error) {
        this.showlowdingBanner = false;
        alert(`Error uploading image ${error.message}`);
      }
    },
    formatCategory(inputString) {
      const words = inputString.toLowerCase().split("_");
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      return capitalizedWords.join(" ");
    },
    handleImagesUpdated(images) {
      const uploadedImage = images[0];
      console.log("Uploaded image:", uploadedImage);
    },
    handleImagesUpdatedBanner(imagesBanner) {
      const uploadedImage = imagesBanner[0];
      console.log("Uploaded image:", uploadedImage);
    },
    async ajoutactuality() {
      this.actualityInfo.overview = this.overview;
      this.actualityInfo.subtitle = this.subtitle;
      this.actualityInfo.description = this.description;
      this.actualityInfo.isLocked = this.Premium;
      this.actualityInfo.actualityCategoryId = this.$route.params.id;
      this.actualityInfo.imgUrlId = this.imageId;
      this.actualityInfo.imgBannerUrlId = this.imageIdBanner;
      const response = await fetch(Constant.QORANI + "actuality/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.actualityInfo),
      });

      try {
        window.location.reload();
      } catch (error) {
        alert(
          "Une erreur est survenue lors de l'ajout du theme, veuillez réessayez plus tard, Merci."
        );
      }
    },
  },
  async created() {},
  computed: {
    filteredHeaders() {
      if (
        this.$route.params.category === "PERSONNAGE" ||
        this.$route.params.category === "PATRIMOINE" ||
        this.$route.params.category === "ARTICLE"
      ) {
        return this.headers.filter((header) => header.value !== "subtitle");
      }
      return this.headers;
    },
  },
};
</script>
<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
</style>
