var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticStyle:{"padding-left":"25px","margin-left":"20px","padding-right":"10px","margin-right":"10px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-btn',{staticClass:"info  ml-6 mr-2 mt-4 ",on:{"click":function($event){_vm.addCard = !_vm.addCard}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDatabasePlusOutline)+" ")]),_vm._v(" Ajouter "+_vm._s(_vm.formatCategory(this.$route.params.category))+" ")],1)],1)],1)],1)],1),(_vm.addCard)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-card',{staticClass:"mb-6 ml-6 mr-2 mt-1"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card-title',[_vm._v(" Ajouter "+_vm._s(_vm.formatCategory(this.$route.params.category)))]),_c('v-card-text',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Titre","outlined":"","required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Overview","outlined":"","required":""},model:{value:(_vm.overview),callback:function ($$v) {_vm.overview=$$v},expression:"overview"}})],1),(
                  _vm.$route.params.category !== 'PERSONNAGE' &&
                    _vm.$route.params.category !== 'PATRIMOINE' &&
                    _vm.$route.params.category !== 'ARTICLE'
                )?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Sous-Titre","outlined":"","required":""},model:{value:(_vm.subtitle),callback:function ($$v) {_vm.subtitle=$$v},expression:"subtitle"}})],1):_vm._e(),(
                  _vm.$route.params.category !== 'PERSONNAGE' &&
                    _vm.$route.params.category !== 'PATRIMOINE'
                )?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('VueEditor',{attrs:{"auto-grow":"","outlined":"","label":"Description","placeholder":"Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticStyle:{"margin-top":"16px"},attrs:{"cols":"auto"}},[_c('span',[_vm._v("Premium:")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-switch',{attrs:{"hide-details":"","inset":""},model:{value:(_vm.Premium),callback:function ($$v) {_vm.Premium=$$v},expression:"Premium"}})],1),_c('v-col',{staticStyle:{"margin-top":"16px"},attrs:{"cols":"auto"}},[(!_vm.Premium)?_c('v-icon',{attrs:{"color":"#00a325"}},[_vm._v(" "+_vm._s(_vm.icons.mdiLockOpen)+" ")]):_vm._e(),(_vm.Premium)?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.icons.mdiLock)+" ")]):_vm._e()],1)],1)],1),(_vm.title)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('vue-upload-multiple-image',{attrs:{"idUpload":_vm.imagesId,"data-images":_vm.images,"name":"images","drag-text":"Veuillez ajoutez une image","browse-text":"Browse image","primary-text":"Primary image","mark-is-primary-text":"Set default image","min-image":1,"multiple":false,"showEdit":false,"popup-text":"Description default image","drop-text":"Drag and drop"},on:{"upload-success":_vm.uploadImageSuccess,"before-remove":_vm.beforeRemove,"images-updated":_vm.handleImagesUpdated},model:{value:(_vm.images),callback:function ($$v) {_vm.images=$$v},expression:"images"}}),(_vm.showlowding && _vm.uploadPercentage < 100)?_c('v-progress-linear',{staticStyle:{"max-width":"190px"},attrs:{"color":"primary","indeterminate":"","rounded":"","height":"15"}},[_c('strong',[_vm._v(_vm._s(Math.ceil(_vm.uploadPercentage))+"%")])]):_vm._e()],1):_vm._e(),(
                  (_vm.title && _vm.$route.params.category === 'PERSONNAGE') ||
                    (_vm.title && _vm.$route.params.category === 'PATRIMOINE') ||
                    (_vm.title && _vm.$route.params.category === 'ARTICLE')
                )?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('vue-upload-multiple-image',{attrs:{"idUpload":_vm.imagesIdBanner,"data-images":_vm.imagesBanner,"name":"images","drag-text":"Veuillez ajoutez une image Banner!","browse-text":"Browse image","primary-text":"Primary image","mark-is-primary-text":"Set default image","min-image":1,"multiple":false,"showEdit":false,"popup-text":"Description default image","drop-text":"Drag and drop"},on:{"upload-success":_vm.uploadImageSuccessBanner,"before-remove":_vm.beforeRemoveBanner,"images-updated":_vm.handleImagesUpdatedBanner},model:{value:(_vm.imagesBanner),callback:function ($$v) {_vm.imagesBanner=$$v},expression:"imagesBanner"}}),(_vm.showlowdingBanner && _vm.uploadPercentageBanner < 100)?_c('v-progress-linear',{staticStyle:{"max-width":"190px"},attrs:{"color":"primary","indeterminate":"","rounded":"","height":"15"}},[_c('strong',[_vm._v(_vm._s(Math.ceil(_vm.uploadPercentageBanner))+"%")])]):_vm._e()],1):_vm._e()],1),_c('v-row',[(
                  _vm.$route.params.category === 'PERSONNAGE' ||
                    _vm.$route.params.category === 'PATRIMOINE'
                )?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('v-btn',{staticClass:"info",attrs:{"disabled":_vm.title.length <= 0},on:{"click":function($event){return _vm.ajoutactuality()}}},[_vm._v(" Suivant ")])],1)]):(_vm.$route.params.category === 'ARTICLE')?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('v-btn',{staticClass:"info",attrs:{"disabled":_vm.title.length <= 0 || _vm.description.length <= 0},on:{"click":function($event){return _vm.ajoutactuality()}}},[_vm._v(" Suivant ")])],1)]):_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('v-btn',{staticClass:"info",attrs:{"disabled":_vm.title.length <= 0 ||
                        _vm.subtitle.length <= 0 ||
                        _vm.description.length <= 0},on:{"click":function($event){return _vm.ajoutactuality()}}},[_vm._v(" Suivant ")])],1)])],1)],1)],1)],1)],1)],1):_vm._e(),_c('v-card',{staticClass:"mb-6 ml-6 mt-6 mr-2"},[_c('v-card-title',[_vm._v("Liste des "+_vm._s(_vm.formatCategory(this.$route.params.category)))]),_c('v-text-field',{staticStyle:{"padding":"15px"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":"","search":_vm.search},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"footer-props":{
        'items-per-page-options': [5, 10, 15, 30],
      },"item-key":"id","headers":_vm.filteredHeaders,"items":_vm.actualityList},scopedSlots:_vm._u([{key:"item.isLocked",fn:function(ref){
      var item = ref.item;
return [(item.isLocked)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiLock)+" ")]):_vm._e(),(!item.isLocked)?_c('v-icon',{attrs:{"color":"#00a325"}},[_vm._v(" "+_vm._s(_vm.icons.mdiLockOpen)+" ")]):_vm._e()]}},{key:"item.imgUrl",fn:function(ref){
      var item = ref.item;
return [_c('v-img',{staticStyle:{"margin":"10px"},attrs:{"src":item.imgUrl,"max-width":"120","max-height":"80"}}),_c('v-divider')]}},{key:"item.modifier",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.redirectToEditActuality(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}},{key:"item.supprimer",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.suppressionAction(item.id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])]}}])})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"530"},model:{value:(_vm.dialogRemoveActuality),callback:function ($$v) {_vm.dialogRemoveActuality=$$v},expression:"dialogRemoveActuality"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Etes vous sur de vouloir supprimer l'actualité ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info darken-1 ","text":""},on:{"click":function($event){return _vm.deleteActuality()}}},[_vm._v(" Confirmer ")]),_c('v-btn',{attrs:{"color":"error darken-1 error","text":""},on:{"click":function($event){_vm.dialogRemoveActuality = false}}},[_vm._v(" Annuler ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }